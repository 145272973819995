import React from "react";

export default function MainIntro () {
    return (
        <div className='about-me'>
            <p>
                Hey, welcome! I am Jimmy, 
            </p>
            <p>
                an SDE doing work on Android
            </p>
        </div>
    );
}