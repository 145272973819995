import React, { useState } from "react";
import { isMobile } from 'react-device-detect';

const contact = "EMAIL"
const email_address = "jim.zd.lin@gamil.com"
const copied = "Copied!"

const linked_in = "LINKEDIN"

export default function Contact () {
    const [message, setMessage] = useState(contact)

    const email_desktop = (
        <div className='contact' >
            <p onMouseEnter={() => setMessage(email_address)} 
                onMouseLeave={() => setMessage(contact)}
                onClick={() => {
                    navigator.clipboard.writeText(email_address)
                    setMessage(copied)
                }}
            >
                {message}
            </p>
            <p onClick={()=> window.open("https://www.linkedin.com/in/jimmy-lin-4a1622173/", "_blank")}>
                LINKEDIN
            </p>
        </div>
    )

    const email_mobile = (
        <div className='contact' >
            <p onMouseLeave={() => setMessage(contact)}
                onClick={() => {
                    if (message === email_address) {
                        navigator.clipboard.writeText(email_address)
                        setMessage(copied)
                    } else {
                        setMessage(email_address)
                    }
                }}
            >
                {message}
            </p>
            <p onClick={()=> window.open("https://www.linkedin.com/in/jimmy-lin-4a1622173/", "_blank")}>
                LINKEDIN
            </p>
        </div>
    )
    return isMobile ? email_mobile : email_desktop;
}